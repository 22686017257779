import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入第三方图标
import "font-awesome/css/font-awesome.min.css";

// 引入vant组件
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 引入第三方图标
import "@/assets/icon/iconfont.css";
import "@/assets/icon/iconfont.js";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



// 引入axios
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
