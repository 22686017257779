<template>
  <div id="app">
    <router-view />
     <audio
      loop="true"
      id="bgmusic"
      ref="MusicPlay"
      :src="form.bg_audio"
    >
    </audio>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form:{},
    }
  },
  mounted(){
    if (
      sessionStorage.getItem("eqcode") == "" ||
      sessionStorage.getItem("eqcode") == null ||
      sessionStorage.getItem("eqcode") == "undefined"
    ) {
      console.log("进入了阿萨达》》》",this);
      let params = this.getRequestParams();

      console.log(params.id);
      this.paid = params.id;
      if(this.paid == ''){
        this.paid = 8
      }
      this.paid = this.paid.match(/\d+/g);
      this.paid = this.paid + "";
      console.log("this.paid>>>>>>", this.paid); //111
      sessionStorage.setItem("eqcode", this.paid);
      let timeds = setTimeout(() => {
        this.axios
          .get("https://dgxf-hdyx.zyxfkj.cn/api/article/get_info?id=" + this.paid)
          .then((res) => {
            console.log(res);
            this.form = res.data.data.data;
            console.log(this.form.image_input);
          });
        clearTimeout(timeds);
      }, 500);
    } else {
      console.log("进入了缓存1234");
      this.axios
        .get(
          "https://dgxf-hdyx.zyxfkj.cn/api/article/get_info?id=" +
            sessionStorage.getItem("eqcode")
        )
        .then((res) => {
          console.log('res>12313>>',res);
          this.form = res.data.data.data;
          console.log(this.form.image_input);
        });
    }
  },
  methods:{
    getRequestParams() {
      let url = window.location.href;
      let requestParams = {};
      if (url.indexOf("?") !== -1) {
        let str = url.substr(url.indexOf("?") + 1); //截取?后面的内容作为字符串
        console.log(str, "?后面的内容");
        let strs = str.split("&"); //将字符串内容以&分隔为一个数组
        console.log(strs, "以&切割的数组");
        for (let i = 0; i < strs.length; i++) {
          requestParams[strs[i].split("=")[0]] = decodeURI(
            strs[i].split("=")[1]
          );
          // 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
        }
      }
      console.log(requestParams, "处理后的对象");
      return requestParams;
    },
  }
}
</script>


<style lang="scss">
.icon {
  widows: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
