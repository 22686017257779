import Vue from "vue";
// import App from '../views/App'
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/search",
    name: "search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/usermassage",
    name: "usermassage",
    component: () => import("../views/UserMassage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/MovieDetail.vue"),
  },
  {
    path: "/",
    name: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component:Index,
  },
  {
    path: "/paihang",
    name: "paihang",
    component: () => import("../views/Paihang.vue"),
    meta: {
      title: "排行榜",
      // keepAlive: true
    },
  },
  {
    path: "/me",
    name: "me",
    component: () => import("../views/Me.vue"),
    meta: {
      title: "活动规则",
      // keepAlive: true
    },
  },

  // {
  //   path: "/home",
  //   name: "home",
  //   component: HomeView,
  //   // 重定向到首页
  //   redirect: "/home/index",
  //   children: [
  //     {
  //       meta: { keepAlive: true },
  //       path: "index",
  //       name: "index",
  //       component: () => import("../views/Index.vue"),
  //     },
  //
  //
  //     },
  //   ],
  // },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next)=>{
  // this.$refs.MusicPlay.play()
	next()
})

export default router;
