<template>
  <div class="main" :style="bg">
    <header>
      <div
        @click="paihangban"
        style="
          padding: 25px 25px;
          color: #fff;
          width: 100px;
          height: 50px;
          line-height: 50px;
          font-weight: 700;
          text-shadow: 3px 3px 3px #df7c1a;
          font-size: 22px;
        "
      >
        <img
          style="width: 90px; height: 40px"
          src="images/paihang.png"
          alt=""
        />
      </div>
      <div class="jingnang">
        <div class="pol">
          <img
            style="width: 70px; height: 70px"
            src="/images/jingnang.png"
            alt=""
          />
          <div class="active" @click="goguize">活动锦囊</div>
        </div>
      </div>
    </header>
    <div class="liu" @click="liu" :style="btn">
      <div>溜起来</div>
    </div>
    <audio id="bgmusics" src="dididada.wav"></audio>
    <audio id="bgmusic" :src="form.bg_audio"></audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      bg: { backgroundImage: "" },
      btn: { backgroundImage: "" },
      paid: "",
      userData: "",
      count: 1,
    };
  },
  created() {},
  mounted() {
    let dida = document.getElementById("bgmusics");
      dida.pause();
    this.userData = sessionStorage.getItem("usercode");
    if (
      sessionStorage.getItem("eqcode") == "" ||
      sessionStorage.getItem("eqcode") == null ||
      sessionStorage.getItem("eqcode") == "undefined"
    ) {
      console.log("进入了");
      let params = this.getRequestParams();
      console.log(params.id);
      this.paid = params.id;
      if (this.paid == "") {
        this.paid = 8;
      }
      this.paid = this.paid.match(/\d+/g);
      this.paid = this.paid + "";
      console.log("this.paid>>>>>>", this.paid); //111
      sessionStorage.setItem("eqcode", this.paid);
      let timeds = setTimeout(() => {
        this.axios
          .get("https://dgxf-hdyx.zyxfkj.cn/api/article/get_info?id=" + this.paid)
          .then((res) => {
            console.log("res>>>", res);
            this.form = res.data.data.data;
            console.log(this.form.image_input);
            this.bg.backgroundImage = "url(" + this.form.image_input + ")";
            this.btn.backgroundImage = "url(" + this.form.image_btn + ")";
          });
        clearTimeout(timeds);
      }, 500);
    } else {
      console.log("进入了缓存");
      this.axios
        .get(
          "https://dgxf-hdyx.zyxfkj.cn/api/article/get_info?id=" +
            sessionStorage.getItem("eqcode")
        )
        .then((res) => {
          console.log("res>>>", res);
          this.form = res.data.data.data;
          console.log(this.form.image_input);
          this.bg.backgroundImage = "url(" + this.form.image_input + ")";
          this.btn.backgroundImage = "url(" + this.form.image_btn + ")";
        });
    }
    this.audioAutoPlay("bgmusic");
  },

  methods: {
    audioAutoPlay(id) {
      var audio = document.getElementById(id),
        play = function () {
          audio.play();
          document.removeEventListener("touchstart", play, false);
        };
      audio.play();


      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          play();
        },
        false
      );
      
      document.addEventListener(
        "YixinJSBridgeReady",
        function () {
          play();
        },
        false
      );
      document.addEventListener("touchstart", play, false);
    },
    paihangban() {
      this.$router.push("/paihang");
    },
    liu() {
      this.$parent.$refs.MusicPlay.pause();
      let dida = document.getElementById("bgmusics");
      dida.play();
      if (
        this.userData == "" ||
        this.userData == null ||
        this.userData == "undefined"
      ) {
        let params = { url: "/#/search" };
        this.axios
          .post("https://dgxf-hdyx.zyxfkj.cn/api/wechat/auth", params)
          .then((res) => {
            console.log(res);
            location.href = res.data.data.url;
          });
      } else {
        this.$router.push("/search");
      }
    },
    goguize() {
      this.$router.push("/me");
    },
    getRequestParams() {
      let url = window.location.href;
      let requestParams = {};
      if (url.indexOf("?") !== -1) {
        let str = url.substr(url.indexOf("?") + 1); //截取?后面的内容作为字符串
        console.log(str, "?后面的内容");
        let strs = str.split("&"); //将字符串内容以&分隔为一个数组
        console.log(strs, "以&切割的数组");
        for (let i = 0; i < strs.length; i++) {
          requestParams[strs[i].split("=")[0]] = decodeURI(
            strs[i].split("=")[1]
          );
          // 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
        }
      }
      console.log(requestParams, "处理后的对象");
      return requestParams;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
}
.jingnang {
  position: fixed;
  right: 10px;
  top: 10px;
}
.liu {
  position: fixed;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  background-size: 100% 100%;

  div {
    width: 150px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-shadow: 5px 5px 10px #fdd893;
  }
}
.pol {
  position: relative;
  transform: rotate(10deg);
  animation: 3s big infinite;
}
.active {
  position: absolute;
  top: 40%;
  left: 55%;
  width: 60px;
  transform: translate(-50%);
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  text-shadow: 5px 5px 10px #fdd893, -5px -5px 10px #fdd893;
}
@keyframes big {
  0% {
    transform: scale(1) rotate(10deg);
  }
  50% {
    transform: scale(1.19) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(10deg);
  }
}
</style>
