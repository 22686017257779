import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: window.sessionStorage.getItem("name"),
    list: JSON.parse(localStorage.getItem("history")),
    comment: {
      title: "",
      pic: "",
      date: "",
    },
  },
  getters: {},
  mutations: {
    updateLogin(state, payload) {
      this.state.name = payload;
      window.sessionStorage.setItem("name", payload);
    },
    history(state, payload) {
      if (state.list == null) {
        state.list = [];
      }
      state.list.push(payload);
      localStorage.setItem("history", JSON.stringify(state.list));
    },
  },
  actions: {},
  modules: {},
});
